import { Inject, Injectable } from '@angular/core';
import { AppConfig } from '../models/app-config.model';
import { SetupService } from './setup.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { readQueryParameters } from '../shared-functions';
import { forkJoin, of, Observable, throwError, BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map, catchError } from 'rxjs/operators';
import { Application } from '../classes/application.class';
import { LANGUAGES } from '../resources/languages';
import { Organization } from '../classes/organization.class';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AppConfigService extends SetupService {

  private _config: AppConfig;
  private _referrer: string;
  private _jobCompanyLogo$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    http: HttpClient,
    translateService: TranslateService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    super(http, translateService, _document);
  }

  get config(): AppConfig {
    return this._config;
  }

  get organization(): Organization {
    return this._config.organization;
  }

  get isAllJobs(): boolean {
    return this._config.organization.isAllJobs;
  }

  get organizationLanguage(): string {
    return this.organization.language;
  }

  get referrer(): string {
    return this._referrer;
  }

  set referrer(referer: string) {
    this._referrer = referer;
  }

  get jobCompanyLogo$(): Observable<string> {
    return this._jobCompanyLogo$.asObservable();
  }

  setJobCompanyLogo(companyLogo: string): void {
    this._jobCompanyLogo$.next(companyLogo);
  }

  initialize(): Observable<AppConfig> {
    console.log(document.referrer);

    if (document.referrer && !document.referrer.includes('localhost')) {
      this.referrer = document.referrer;
    } else if (!document.referrer && !document.referrer.includes('localhost')) {
      this.referrer = 'direct traffic';
    }

    const applicationInfo$: Observable<Application> = window.location.href.includes('universal-job-update') ?
      this.getUniversalJobUpdateApplicationInfo() : readQueryParameters('application') ?
        this.getApplicationInfo()
          .pipe(
            map((application) => {
              if (application.isUniversalJob && !application.jobInfo.company.useUniversalJob) {
                return { jobInfo: null, jobApplication: null, inactiveJob: true };
              }
              return application;
            }),
            catchError((error: HttpErrorResponse) => {
              if (error.error.errors.job === 'Job is not active anymore.') {
                // window.location.href = `${window.location.origin}/jobs`;
                return of({ jobInfo: null, jobApplication: null, inactiveJob: true });
              }
              if (error.error.errors === 'Action cannot be perform, because application has erased candidate data.') {
                return of({ jobInfo: null, jobApplication: null, candidateDataErased: true });
              }

              return throwError(() => error);
            })
          )
        : of({ jobInfo: null, jobApplication: null });

    return forkJoin([
      this.getOrganizationInfo(),
      applicationInfo$
    ]).pipe(
      map(([organization, { jobInfo, jobApplication, inactiveJob, candidateDataErased }]) => {
        if (!organization) {
          window.location.href = 'https://higherjobs.uhigher.com';
        }

        this._config = {
          organization,
          job: jobInfo,
          jobApplication,
          inactiveJob,
          candidateDataErased
        };

        this.setLanguage(organization);

        return this._config;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403 || error.status === 404) {
          window.location.href = 'https://higherjobs.uhigher.com';
        }

        return throwError(() => error);
      })
    );
  }

  setLanguage(company: Organization): void {
    const path = window.location.pathname;
    const language = LANGUAGES.find(lang => path.includes(`/${lang.iso6391}`))?.iso6391;

    this.translateService.use(language || company.candidatePortalLanguage || company.language);
  }
}
