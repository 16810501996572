import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { readQueryParameters } from '../shared-functions';
import { Country, ICompany, IEnterprise } from '../models/company.model';
import { TranslateService } from '@ngx-translate/core';
import { IApplication } from '../models/application.model';
import { Application } from '../classes/application.class';
import { Pagination } from '../models/pagination.interface';
import { Enterprise, Company, Organization, AllJobsOrganization } from '../classes/organization.class';
import { JOBS_PAGES, JobsPages } from '../models/jobs-pages';
import { DOCUMENT } from '@angular/common';

//add ica
const ALL_JOBS_DOMAINS: string[] = [JobsPages.higher, JobsPages.ica];

@Injectable({
  providedIn: 'root',
})
export class SetupService {

  protected _domain: string;
  protected _isEnterprise = false;

  get domain(): string {
    return this._domain;
  }

  constructor(
    private http: HttpClient,
    protected translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  getOrganizationInfo(): Observable<Organization> {
    this.setDomain();

    if (ALL_JOBS_DOMAINS.includes(this._domain)) {
      const organization = JOBS_PAGES.get(this._domain);
      if (!organization.countries) {
        let params = new HttpParams();
        params = params.set('limit', 1000);
        return this.http.get(`${environment.countries}`, { params })
          .pipe(
            map(({ data }: Pagination<Country>) => {
              return new AllJobsOrganization(organization, data);
            })
          );
      } else {
        return of(null)
          .pipe(
            map(() => {
              return new AllJobsOrganization(organization);
            })
          );
      }
    }


    if (this._isEnterprise) {
      let params = new HttpParams();
      params = params.set('enterpriseDomain', this._domain);

      return this.http.get(`${environment.enterprises}`, { params })
        .pipe(
          map(({ data }: Pagination<IEnterprise>) => {
            return data[0] ? new Enterprise(data[0]) : null;
          })
        );
    }

    return this.http.get(`${environment.companyInfo}/${this._domain}`)
      .pipe(
        map(({ data }: Pagination<ICompany>) => {
          return data[0] ? new Company(data[0]) : null;
        })
      );
  }

  setDomain(): void {
    const { host, hostname } = window.location;

    if (hostname === 'localhost' || hostname.includes('192.168.') || hostname.includes('bs-local')) {
      // this._domain = 'demo';
      this._domain = JobsPages.ica;
      // this._domain = JobsPages.higher;
      // this._domain = 'final';
      // this._isEnterprise = true;
      return;
    }

    const externalDomain = environment.externalDomains
      .find(domainObject => host.includes(domainObject.externalDomain));

    if (externalDomain) {
      this._domain = externalDomain.companyDomain;
      return;
    }

    const domain: string[] = hostname.split('.');

    if (domain[0] === 'www') {
      domain.shift();
    }

    if (domain[1] === 'global') {
      this._isEnterprise = true;
    }

    const higherIndex = domain.findIndex((domainItem) => domainItem === 'higher' || domainItem === 'uhigher');
    if (higherIndex !== 1 && higherIndex === 2 && !this._isEnterprise) {
      window.location.href = 'https://higherjobs.uhigher.com';
    }

    this._domain = domain[0];

    if (this._domain === 'icajobs') {
      this.document.getElementById('appTitle').textContent = 'Ica Karriär';
      this.document.getElementById('appFavicon').setAttribute('href', 'assets/images/faviconIca.ico');
    }
  }

  getApplicationInfo(applicationGuid?: string): Observable<Application> {
    const guid = applicationGuid || readQueryParameters('application');

    return this.http.get(`${environment.applications}/${guid}/for-candidate-portal?context=candidate_journey`)
      .pipe(
        map((data: IApplication) => new Application(data))
      );
  }

  getUniversalJobUpdateApplicationInfo(): Observable<Application> {
    const guid = readQueryParameters('application');

    return this.http.get(`${environment.applications}/${guid}/for-candidate-portal?context=candidate_edit_selected_answers_via_reminder_link`)
      .pipe(
        map((data: IApplication) => new Application(data))
      );
  }

}
